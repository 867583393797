export default {
	name: 'Test',
	init({context}) {
		console.log('context.request ==========================>');
		console.dir(context.request, {colors: true, depth: 3});
	},
	mounted() {
		console.dir(this, {colors: true, depth: null});
	}
};
